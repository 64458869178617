export default [
  {
    text: 'Home',
    to: '/'
  },
  {
    dropdown: [
      {
        text: 'Medical Professionals',
        to: '/medical-care-professionals/'
      },
      {
        text: 'Personal Advice',
        to: '/personal-advice/'
      },
      {
        text: 'Mortgage Advice',
        to: '/mortgage-advice/'
      },
      {
        text: 'Parinity F.I.R.E',
        to: '/parinity-fire/'
      }
    ],
    width: '220px',
    text: 'Services'
  },
  {
    text: 'Our Team',
    to: '/about-us/'
  },
  {
    text: 'Pathways',
    to: '/pathways/'
  },
  {
    text: 'Our Clients',
    to: '/testimonials/'
  },
  {
    text: 'Contact Us',
    to: '/contact/'
  }
]
