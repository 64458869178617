export default [
  {
    text: 'Services',
    links: [
      {
        text: 'Medical Professionals',
        to: '/medical-care-professionals/',
      },
      {
        text: 'Personal Advice',
        to: '/personal-advice/',
      },
      {
        text: 'Mortgage Advice',
        to: '/mortgage-advice/',
      },
    ],
  },
  {
    links: [
      {
        text: 'Our Team',
        to: '/about-us',
      },
      {
        text: 'Our Clients',
        to: '/testimonials',
      },
      {
        text: 'Contact Us',
        to: '/contact',
      },
      {
        text: 'Pathways',
        to: '/pathways/',
      },
      {
        text: 'Privacy Policy',
        to: '/privacy-policy/',
      },
    ],
    text: 'About',
  },
]
