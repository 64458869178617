import * as React from 'react'
import Card from '../Card'
import CTACard from '../CTACard'
import {
  SocialMedia,
  Footer as StyledFooter,
  FooterLink,
  FooterList,
  FooterInfo,
  StyledFlex,
  Bottom
} from './styles'

import footerLinks from './footer'
import { NavLogo } from '../Navbar/styles'

const Footer: React.SFC = () => (
  <>
    <StyledFooter>
      <FooterInfo>
        <NavLogo color="dark" />
        <p style={{ marginTop: 10 }}>
          © 2019 Parinity Pty Ltd
          <br />
          All rights reserved.
        </p>
        <SocialMedia>
          <a target="_blank" href="https://instagram.com/gfitwear">
            {/* <Instagram /> */}
          </a>
          <a target="_blank" href="https://facebook.com/gfitwear">
            {/* <Facebook /> */}
          </a>
        </SocialMedia>
      </FooterInfo>
      <StyledFlex>
        {footerLinks.map(item => (
          <FooterList key={item.text}>
            <span>{item.text}</span>
            {item.links.map(link => (
              <FooterLink key={link.text} to={link.to || '#'}>
                {link.text}
              </FooterLink>
            ))}
          </FooterList>
        ))}
        <FooterList>
          <div className="gf-badge">
            <a
              className="gf-badge-icn"
              href="https://growthfinancial.com.au/financial-planner-brisbane/"
            >
              <img
                src="https://assets.growthfinancial.com.au/growth-financial-top-adviser.png"
                alt="Growth Financial Top Adviser"
                width="100px"
                style={{ marginRight: 10 }}
              />
            </a>{' '}
          </div>
        </FooterList>
      </StyledFlex>
    </StyledFooter>
    <Bottom>
      <p>
        {' '}
        Parinity Pty Ltd is authorised by The Parinity Group Pty Ltd. AFSL:
        458943. ABN: 86 169 485 885
        <br />
        Representative of Connective Credit Services Pty Ltd. ACL: 389328. ABN:
        51 143 651 496
      </p>
    </Bottom>
  </>
)

export default Footer
